.first-level-menu {
  .menu-icon {
    font-size: 1rem;
  }

  .active {
    color: $x_light_color;
  }
}

.second-level-menu {
  a {
    font-size: 1.2rem !important;
  }
}
