.footer {
	padding: 60px 0;
	background-color: $dark_color;
	color: $white;
	.uol_logo {
		max-width: 240px;
	}
  .logos_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
  }
  .nasa_partner_logo {
    margin-bottom: 0.75rem;
  }
	.logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
		img {
			width: auto;
			height: 75px;
		}
		.ipac_logo {
			height: 50px;
		}
		.jpl_logo {
			height: 30px;
		}
		.sonoma_logo {
			height: 45px;
		}
    .stsci_logo {
      margin-left: 0;
    }
	}
}

@media (max-width: 991px) {
  .footer {
    .logos_wrapper {
      display: block;
      .uol_logo {
        margin-bottom: 0.75rem;
      }
    }
  }
}
