.home_page {
  margin-top: -93px;
  .landing_video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    position: absolute;
    top: 0;
    left: 0;
  }

  .video_overlay {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    background-color:rgba(0, 0, 0, 0.4);
    z-index:5;
    color:white;
  }
  .flex_div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }
  .lead_content {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .block {
      text-align: center;
      z-index: 6;
    }
    h1 {
      font-size: 5rem;
    }
    .subtitle {
      padding: 0 12rem;
    }
  }
  .shared_sky {
    // @extend .flex_div;
    // height: 100vh;
    background-image: url("/static/images/home-bg.jpg");
  }

  .hundred_wrapper {
    @extend .flex_div;
    color: $x_light_color;
    min-height: 600px;
    text-align: center;
    z-index: 4;
    position: relative;
    overflow-x: hidden;
    .hundred_content {
      z-index: 6;
    }
  }
  .thousand_wishes {
    background-size: cover;
    .background-image {
      opacity: 0.5;
    }
  }
  .thousand_planetariums {
    background-size: cover;
  }
  .planetarium_films {
    background-color: #000;
  }
  .fifty_fifty_wrapper {
    display: flex;
    .fifty, .fifty_image {
      width: 50vw;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .fifty {
      background-color: #2E313A;
    }
    .fifty_image {
      img {
        min-height: 500px;
        object-fit: cover;
      }
      .padded {
        padding: 3rem;
        object-fit: contain;
      }
    }
  }
  .film_shorts_wrapper {
    overflow-x: scroll;
    width: 100vw;
    padding-top: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    .film_shorts {
      // position: absolute;
      //width: 2000px;
      display: flex;
      justify-content: space-between;
      .small_card {
        background-color: transparent;
        width: 200px;

        img {
          border-radius: 50%;
        }
      }
      @media (max-width: 767px) {
        .small_card {
          width: 150px;
        }
      }
    }

  }
  .film_shorts_wrapper::-webkit-scrollbar {
    display: none;
  }
  #oval {
    background-color:white;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
    width:100vw;
    height:66%;
    border-bottom-left-radius:50%;
    border-bottom-right-radius:50%;
  }
  .background-image {
    background-color:white;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
    width:100vw;
    height: 100%;
  }

  .small_card {
    background-color: #fff;
    border-radius: 0.25rem;
    color: #4a4a4a;
    max-width: 100%;
    position: relative;
    .small_card_image {
      display: block;
      position: relative;
      &:first-child img {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }
    }
    .card-content {
      background-color: transparent;
      padding: 1.5rem;
      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      h4 {
        color: black;
      }
    }
  }
  .card_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    .lead_content {
      h1 {
        font-size: 3rem;
      }
      .subtitle {
        padding: 0;
      }
    }
    .fifty_fifty_wrapper {
      .fifty {
        width: 100vw;
      }
      .fifty_image {
        display: none;
      }
    }
  }
}
