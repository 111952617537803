html {
  overflow-x: hidden;
}

body {
	font-family: $primary_font, sans-serif;
	font-size: 18px;
  line-height: 1.5;
	color: $white;
	background-color: #000;
}

a {
	transition: all 0.2s;
	text-decoration: none;
}

p {
  color: $secondary_font_color
}

hr, .hr {
	padding: 0;
	margin: 60px -40px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

strong, .strong {
	font-weight: 700;
}

weak, .weak {
	font-weight: 300;
}

.em {
  font-style: italic;
}

.richtext-image {
  margin-bottom: 2rem;
}

img {
	width: 100%;
}

.round_image {
  border-radius: 50%;
}

.hidden {
  display: none;
}

.visible {
  display: inherit
}

.center {
	text-align: center;
}

.left {
	float: left;
}

.right {
	float: right;
}

.secondary_font {
  font-family: $secondary_font;
}

.offset_anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

h1, h2, h3, h4, h5, h6, .big_title {
  font-family: $secondary_font, sans-serif;
  color: $x_light_color;
  font-size: 2rem;
  line-height: 1.125;
  word-break: break-word;
  margin: 0;
  padding: 0;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  &:not(.is-spaced) + .subtitle {
    margin-top: -1.25rem;
  }
}

.big_title {
  font-size: 4rem;
}


h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.25;
  word-break: break-word;
  margin: 0;
  padding: 0;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.block:not(:last-child) {
  margin-bottom: 1.5rem;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
  &:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  &:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
  &:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  &:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  &:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }
  &:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
}

.yield_wrapper {
  margin-top: 93px;
}

.is_flex {
  display: flex;
  flex-wrap: wrap;
}

.fit-cover {
  object-fit: cover;
  object-position: center;
}

// These name spaces keep the styles from overwriting css frameworks default values
.cke_editable, .cke_panel_listItem, .raw {
	ol {
		margin-right: 0;
		padding: 0 40px;
	}
	ul {
		margin-right: 0;
		padding: 0 40px;
	}
	dl {
		margin-right: 0;
		padding: 0 40px;
	}
	h1, h2, h3, h4, h5, h6 {
	  font-family: $primary_font, sans-serif;
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	.strong_paragraph {
	  background-color: #ccc;
	}
	.weak_paragraph {
	  color: #aaa;
	}
	.image_container, .video_container {
		text-align: center;
		background-color: #cfcfcf;
		.caption {
			padding: 10px 20px;
			margin: 0;
			font-style: italic;
			background-color: #efefef;
		}
	}
	.image_container {
		overflow: hidden;
		&.left {
			max-width: 30%;
			margin-right: 15px;
		}
		&.right {
			max-width: 30%;
			margin-left: 15px;
		}
		&.center {
			margin-bottom: 20px;
		}
		img {
			height: 100%;
		  width: 100%;
			max-height: 400px;
		  object-fit: cover;
		}
	}
	.video_container {
		overflow: hidden;
		&.left {
			max-width: 30%;
			margin-right: 15px;
		}
		&.right {
			max-width: 30%;
			margin-left: 15px;
		}
		&.center {
			margin-bottom: 20px;
		}
		.aspect_ratio {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56%;
			iframe {
				position: absolute;
			  width: 100%;
			  height: 100%;
			  left: 0;
				top: 0;
			}
		}
		.caption {
			position: relative;
			z-index: 10;
		}
	}
}

.card {
	border: none;
	.card-subtitle {
		margin-bottom: 20px;
	}
	.card-text {
		p:last-child {
			margin-bottom: 0;
		}
	}
}
.video_wrapper {
	// border-radius: 0.25rem;
	// overflow: hidden;
	padding-top: 56.25%;
  width: 100%;
  position: relative;
	iframe, img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	  width: 100%;
		height: 100%;
	}
  img {
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0,140,186,0.8);
    padding: 16px;
    color: $x_light_color;
  }
  .hover_text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
  }
  &:hover .overlay {
    opacity: 1;
  }
}

.offset_anchor {
	display: block;
	position: relative;
	top: -60px;
	visibility: hidden;
}

.flash {
  .alert {
    cursor: pointer;
    margin: 0;
    border-radius: 0px;
  }
}

.devise {
  padding: 60px 0;
}

// Bulma CSS
.section {
  padding: 3rem 1.5rem;
  display: block;
}

.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

.box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 10%), 0 0 0 1px rgba(10, 10, 10, 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}

.one_by_one, .four_by_three, .sixteen_by_nine {
  img, iframe, video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.one_by_one_wrapper {
  min-width: 100px;
  height: 100px;
  margin-right: 20px;
  display: flex;
}

.one_by_one {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.four_by_three {
  width: 100%;
  padding-top: 75%;
  position: relative;
}

.sixteen_by_nine {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.div_overlay {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0, 0, 0, 0.2);
  z-index:5;
  color:white;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.image-background {
    background-attachment: fixed;
    background-size: 100% 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
}

.align-items-center {
  align-items: center;
}

@media screen and (max-width: 2400px) {
  .image-background {
      background-size: cover;
      background-position: center center;
  }

}


@media (min-width: 1200px) {
  .hover_text {
		-webkit-line-clamp: 6;
  }
}

@media (max-width: 1199px) {
  .hover_text {
		-webkit-line-clamp: 4;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem 3rem;
  }
  .section.is-medium {
      padding: 9rem 4.5rem;
  }
  .section.is-large {
    padding: 18rem 6rem;
  }
}

@media (max-width: 991px) {
  .hover_text {
		-webkit-line-clamp: 3;
  }
}
@media (max-width: 767px) {
	// sm small
	.image_container, .video_container {
		float: none !important;
		margin: 0 0 20px 0 !important;
		&.left {
			max-width: none !important;
		}
		&.right {
			max-width: none !important;
		}
	}
  .hover_text {
		-webkit-line-clamp: 8;
  }
  .hero-body {
    padding: 3rem 3rem;
  }
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }
  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
  .planetarium_form {
    h1.black_font_color {
      font-size: 2.5rem;
    }
    iframe {
      height: 5000px;
    }
  }
  .big_title {
    font-size: 3rem;
  }
}
