.main-nav {
  .container-fluid {
    border-bottom: 1px solid #ffffff40;
    background: inherit;
  }

  &:not(.mobile-on) {
    .inner-nav {
      li a {
        height: 65px !important;
        line-height: 62px !important;
      }
    } 
  }

  .inner-nav {
    ul {
      font-size: 16px;
    }
  }

  .mobile-on .desktop-nav ul li {
    a:hover, a.active {
      background: rgba(255,255,255, .2);
    }
  }
}

.small-section {
  padding: 120px 0 100px 0;
}
