.article {
  margin-bottom: 40px;
  .article_wrapper {
      img {
        border-radius: 0.25rem 0.25rem 0 0;
        object-fit: cover;
        object-position: top;
      }
      .primary {
        height: 620px;
      }
      .secondary {
        height: 400px;
      }
      .tertiary {
        height: 300px;
      }
  }
  .related_item {
    margin-bottom: 20px;
    img {
      margin-bottom: 10px;
    }
  }
}
