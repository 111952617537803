.navigation {
  padding: 0.5rem 3rem;
  color: $white;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  width: 100%;
  -webkit-transition: background 0.6s ease;
  transition: background 0.6s ease;
  .logo {
    flex:1;
    display: flex;
    justify-content: center;
    transform: translateX(12px);/*D element Width[if needed]*/
    .brand {
      img {
        width: 92px;
      }
    }
    .homepage_logo {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
    &:hover {
      color: $white;
    }
  }
  a, a:link, a:visited, a:active {
    color: $white;
  }
  a:hover {
    color: #fa0;
  }
  a.disabled {
    color: #888;
    cursor: default;
  }
}
.navigation.dark_header {
  background:#000;
}
.navigation.clear_header {
  background: transparent;
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 0%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 10%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  font-size: 30px;
  top: 1.5rem;
  right: 1.5rem;
  padding: 0;
}

.openbtn {
  font-size: 30px;
  cursor:pointer;
  z-index: 10;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }
}

@media (max-width: 767px) {
  .navigation {
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    .logo {
      transform: none;
      display: inline-block;
      h2 {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .overlay .closebtn  {
    right: 3rem;
  }
}

