.video {
  &.listing {
    .video_wrapper {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      float: left;
      width: 24%;
    }
    .card-block {
      float: left;
      width: 74%;
    }
  }
  &.series {
    .tag {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 10;
    }
  }
}
.video_page {
  .series_control {
    margin-bottom: 10px;
    .previous_video, .next_video {
      img {
        width: auto;
        height: 48px;
      }
    }
    .previous_video {
      img {
        margin-right: 10px;
      }
    }
    .next_video {
      text-align: right;
      img {
        margin-left: 10px;
      }
    }
  }
  .video_selector {
    position: relative;
    z-index: 20;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    .selector_item {
      position: relative;
      width: 120px;
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 4px;
      img {
        width: 120px;
      }
      a:hover::before {
        position: absolute;
        z-index: 25;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.70;
        background-color: #98fbe5;
        color: #000;
        text-align: center;
        font: normal normal normal 18px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f04b";
        line-height: 67px;
      }
      a.active {
        background-color: #98fbe5;
        .playing_overlay {
          position: absolute;
          z-index: 25;
          top: 0;
          left: 0;
          width: 121px;
          height: 100%;
          background-color: #98fbe5;
          text-align: center;
          color: #000;
          padding-top: 12px;
          line-height: 22px;
        }
      }
    }
  }
  .related_sidebar {
    .related_wrapper {
      margin-bottom: 5px;
      .related_title {
        font-weight: 800;
        margin-bottom: 12px;
        font-size: 20px;
      }
      .related_content {
        margin-bottom: 10px;
        font-size: 90%;
      }
    }
  }
  .series_sidebar {
    margin-top: -64px;
  }
}

.bonus_material_link {
  display: inline-block;
  margin-bottom: 10px;
  &:hover {
    button {
      background-color: #449d44;
    }
  }
}

.series_name {
  font-weight: 800;
  margin-bottom: 12px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .video_page .series_control {
    .previous_video {
      .strong {
        position: absolute;
        bottom: 0;
        left: 108px;
      }
    }
    .next_video {
      .strong {
        position: absolute;
        bottom: 0;
        right: 108px;
      }
    }
  }
}
