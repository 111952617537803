.block-richtext_with_extra_column_block {
  iframe {
    margin-bottom: 20px;
    width: 100%;
    height: 350px;
  }

  .extra-col {
    h1, h2, h3 {
      color: #1a1e21 !important;
    }
  }
}
